import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Typography, Grid, TextField, Button, FormControl, Select, InputLabel, MenuItem, Paper } from "@mui/material";

import { User } from "../models/User";
import { Customer } from "../models/Customer";
import { DeliveryPartner } from "../models/DeliveryPartner";
import { ProfileNames, ProfileValues } from "../models/Profile";

import { CreateUser, GetUser } from "../services/UserService";
import { GetCustomer } from "../services/CustomerService";
import { CreateDeliveryPartner, GetDeliveryPartnerByUser } from "../services/DeliveryPartnerService";
import { GetDistributionCenter } from "../services/DistributionCenterService";
import { ResetPassword } from "../services/UserService";

import Loading from "../components/Loading";
import { SnackbarContext } from "../components/SnackbarProviderWrapper";
import { AlertDialogContext } from "../components/AlertDialogSlide/AlertDialogContext";

export const UserPage = () => {
    const navigate = useNavigate();
    const snackbar = useContext(SnackbarContext);
    const { openAlertDialog } = useContext(AlertDialogContext);

    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);

    const [distributioncenters, setDistributionCenters] = useState(null);
    const [user, setUser] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [deliveryPartner, setDeliveryPartner] = useState(null);
    const [passwordConfirm, setPasswordConfirm] = useState("");

    /* Handles */
    function handleVerification(password) {
        return new Promise((resolve, reject) => {
            /* const MIN_PASSWORD_LENGTH = 8; */

            /* if (!password || password.length < MIN_PASSWORD_LENGTH && user.password === passwordConfirm) { */
            if (user.username !== "" && user.profileId !== "") resolve(true);
            else resolve(false);
        });
    }

    async function HandleSubmit() {
        setLoadingButton(true);
        try {
            const valid = await handleVerification();
            if (valid)
                switch (user.profileId) {
                    case ProfileValues.ADMIN:
                        if (id === "0") {
                            const responseAlert = await new Promise((resolve) => {
                                openAlertDialog({
                                    title: "Confirmación",
                                    content: "¿Está seguro de crear un usuario con permisos de administrador?",
                                    disagreeText: "No",
                                    agreeText: "Sí",
                                    resolve,
                                });
                            });

                            if (responseAlert) {
                                const createUserResponse = await CreateUser(user);
                                if (createUserResponse.Result !== null) {
                                    snackbar.current.enqueueSnackbar("Éxito en la creación del Usuario Admin.", {
                                        variant: "success",
                                    });
                                    await handleResetPassword(user.username);
                                    navigate("/users");
                                } else {
                                    snackbar.current.enqueueSnackbar("Error al crear el Usuario.", {
                                        variant: "error",
                                    });
                                }
                            }
                        }
                        break;

                    case ProfileValues.CUSTOMER:
                        if (id === "0") {
                            snackbar.current.enqueueSnackbar("El Customer solo se puede crear utilizando la aplicación móvil de RosaGas.", {
                                variant: "error",
                            });
                            //const createUserResponse = await CreateUser(user);
                            //if (createUserResponse.Result !== null) {
                            //    customer.userId = createUserResponse.Result.id;
                            //    const createCustomerResponse = await CreateCustomer(customer);
                            //    if (createCustomerResponse.Result !== null) {
                            //        snackbar.current.enqueueSnackbar("Éxito en la creación del Customer.", {variant: 'success', });
                            //        navigate("/users");
                            //    }
                            //    alert("createCustomerResponse, Response: ", createUserResponse.Message);
                            //} else {
                            //    alert(createUserResponse.Message);
                            //}
                        }
                        break;

                    case ProfileValues.DELIVERYPARTNER:
                        if (id === "0") {
                            const createUserResponse = await CreateUser(user);
                            if (createUserResponse.Result !== null) {
                                deliveryPartner.userId = createUserResponse.Result.id;
                                const createDeliveryPartnerResponse = await CreateDeliveryPartner(deliveryPartner);
                                if (createDeliveryPartnerResponse.Result !== null) {
                                    snackbar.current.enqueueSnackbar("Éxito en la creación del Delivery Partner", {
                                        variant: "successs",
                                    });
                                    await handleResetPassword(user.username);
                                    navigate("/users");
                                } else {
                                    snackbar.current.enqueueSnackbar(`Error al crear el socio de Delivery Partner. ${createUserResponse.Message}`, {
                                        variant: "error",
                                    });
                                }
                            } else {
                                console.log(createUserResponse)
                                snackbar.current.enqueueSnackbar(`Error al crear Usuario. ${createUserResponse.Message}`, {
                                    variant: "error",
                                });
                            }
                        }
                        break;

                    default:
                        break;
                }
            else
                snackbar.current.enqueueSnackbar("Faltan campos obligatorios (*)", {
                    variant: "error",
                });
        } catch (error) {
        } finally {
            setLoadingButton(false);
        }
    }

    async function handleResetPassword(username) {
        try {
            const response = await ResetPassword(username);
            if (response.Result !== null)
                snackbar.current.enqueueSnackbar(`Se creó una contraseña temporal para ${username} y se envió al correo electrónico.`, {
                    variant: "success",
                });
        } catch (error) { }
    }
    /* EndHandles */

    async function LoadUser() {
        try {
            const userResponse = await GetUser(id);
            if (userResponse.Result !== null) {
                setUser(userResponse.Result);
            } else {
                setUser(new User());
            }
        } catch (error) {
            setUser(new User());
        }
    }

    async function LoadCustomer() {
        try {
            const response = await GetCustomer(id);
            if (response.Result !== null) {
                setCustomer(response.Result);
            } else {
                setCustomer(new Customer());
            }
        } catch (error) {
            setCustomer(new Customer());
        }
    }

    async function LoadDeliveryPartner() {
        try {
            const response = await GetDeliveryPartnerByUser(id);
            if (response.Result !== null) {
                setDeliveryPartner(response.Result);
            } else {
                setDeliveryPartner(new DeliveryPartner());
            }
        } catch (error) {
            setDeliveryPartner(new DeliveryPartner());
        }
    }

    async function LoadDistributionCenters() {
        try {
            const distributionCenterResponse = await GetDistributionCenter();
            setDistributionCenters(distributionCenterResponse);
        } catch (error) { }
    }

    async function LoadObjects() {
        try {
            await LoadUser();
            await LoadCustomer();
            await LoadDeliveryPartner();
            await LoadDistributionCenters();
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    // useEffect(() => { console.log(user) }, [user])

    useEffect(() => {
        LoadObjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxWidth="xl" className="mainContainerMinHeight">
            <Typography component="h2" align="center" variant="h4" mb={2}>
                {id === "0" ? "Crear Usuario" : "Actualizar Usuario"}
            </Typography>

            {loading ? (
                <Loading />
            ) : user !== null && customer !== null && deliveryPartner !== null ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="profileId">* Perfil</InputLabel>
                            <Select
                                labelId="profileId"
                                id="profile"
                                label="Perfil"
                                value={user.profileId}
                                onChange={(e) => {
                                    setUser({ ...user, profileId: parseInt(e.target.value) });
                                }}
                            >
                                {ProfileNames === null ? (
                                    <MenuItem value="" key="0"></MenuItem>
                                ) : (
                                    Object.entries(ProfileNames).map(([key, value]) => {
                                        return (
                                            <MenuItem value={key} key={key}>
                                                {value}
                                            </MenuItem>
                                        );
                                    })
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="username"
                            label="* Nombre de usuario (email)"
                            variant="outlined"
                            defaultValue={user.username}
                            fullWidth
                            onChange={(e) => {
                                setUser({ ...user, username: e.target.value });
                            }}
                        />
                    </Grid>

                    <>
                        <Grid item xs={12} >
                            <TextField
                                id="password"
                                label="Contraseña"
                                variant="outlined"
                                type="password"
                                defaultValue={user.password}
                                fullWidth
                                onChange={(e) => {
                                    setUser({ ...user, password: e.target.value });
                                }}
                            />
                        </Grid>

                        {/*<Grid item xs={12} sm={6}>
                            <TextField
                                id="passwordConfirm"
                                label="Confirmar Contraseña"
                                variant="outlined"
                                type="password"
                                defaultValue={passwordConfirm}
                                fullWidth
                                onChange={(e) => {
                                    setPasswordConfirm(e.target.value);
                                }}
                            />
                        </Grid>*/}
                    </>


                    {user.profileId !== "" ? (
                        <>
                            {user.profileId !== 1 ? (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="name"
                                            label="* Nombre"
                                            variant="outlined"
                                            defaultValue={user.profileId === ProfileValues.ADMIN ? "" : user.profileId === ProfileValues.CUSTOMER ? customer.firstName : user.profileId === ProfileValues.DELIVERYPARTNER ? deliveryPartner.firstName : ""}
                                            fullWidth
                                            onChange={(e) => {
                                                setCustomer({ ...customer, firstName: e.target.value });
                                                setDeliveryPartner({ ...deliveryPartner, firstName: e.target.value });
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="lastname"
                                            label="* Apellidos"
                                            variant="outlined"
                                            defaultValue={user.profileId === ProfileValues.ADMIN ? "" : user.profileId === ProfileValues.CUSTOMER ? customer.lastName : user.profileId === ProfileValues.DELIVERYPARTNER ? deliveryPartner.lastName : ""}
                                            fullWidth
                                            onChange={(e) => {
                                                setCustomer({ ...customer, lastName: e.target.value });
                                                setDeliveryPartner({ ...deliveryPartner, lastName: e.target.value });
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="identification"
                                            label="* Número de Identificación"
                                            variant="outlined"
                                            type="number"
                                            defaultValue={user.profileId === ProfileValues.ADMIN ? "" : user.profileId === ProfileValues.CUSTOMER ? customer.identification : user.profileId === ProfileValues.DELIVERYPARTNER ? deliveryPartner.identification : ""}
                                            fullWidth
                                            onChange={(e) => {
                                                setCustomer({ ...customer, identification: e.target.value });
                                                setDeliveryPartner({ ...deliveryPartner, identification: e.target.value });
                                            }}
                                        />
                                    </Grid>
                                </>
                            ) : null}

                            {user.profileId === 2 ? (
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="phoneNumber"
                                        label="Teléfono"
                                        variant="outlined"
                                        type="number"
                                        defaultValue={customer.setPhoneNumber}
                                        fullWidth
                                        onChange={(e) => {
                                            setCustomer({ ...customer, phoneNumber: e.target.value });
                                        }}
                                    />
                                </Grid>
                            ) : null}

                            {user.profileId === 3 ? (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="licensePlate"
                                            label="* Placa"
                                            variant="outlined"
                                            defaultValue={deliveryPartner.licensePlate}
                                            fullWidth
                                            onChange={(e) => {
                                                setDeliveryPartner({ ...deliveryPartner, licensePlate: e.target.value });
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="distributionCenterId">Centro de Distribución</InputLabel>
                                            <Select
                                                labelId="distributionCenterId"
                                                id="distributionCenter"
                                                label="Centro de Distribución"
                                                value={deliveryPartner.distributionCenterId}
                                                onChange={(e) => {
                                                    setDeliveryPartner({ ...deliveryPartner, distributionCenterId: parseInt(e.target.value) });
                                                }}
                                            >
                                                {distributioncenters === null ? (
                                                    <MenuItem value="" key="0"></MenuItem>
                                                ) : (


                                                    distributioncenters.Result.map((item) => {
                                                        return (
                                                            <MenuItem value={item.id} key={item.id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    })


                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>
                            ) : null}
                        </>
                    ) : null}

                    {/* <Grid item xs={12} sm={6}>
                  <FormControlLabel control={<Checkbox defaultChecked={user.active} />} label="Activo" />
               </Grid> */}

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    fullWidth
                                    onClick={() => {
                                        navigate("../users");
                                    }}
                                >
                                    Cancelar
                                </Button>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                {/* <Button
                           variant="contained"
                           fullWidth
                           onClick={() => {
                              HandleSubmit();
                           }}
                        >
                           {id === "0" ? "Crear" : "Actualizar"}
                        </Button> */}

                                {loadingButton ? (
                                    <Button variant="contained" fullWidth disabled>
                                        {id === "0" ? "Crear" : "Actualizar"} &nbsp;
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={() => {
                                            HandleSubmit();
                                        }}
                                    >
                                        {id === "0" ? "Crear" : "Actualizar"}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}
        </Container>
    );
};
